import BaseService from "./base.service";

class ThemesService {

    getThemesList(){
        return BaseService.apiGetRequest('auth/getthemeslist');
    }   
    getTheme(id){
        return BaseService.apiGetRequest('auth/gettheme/'+id);
    }
    getUserWebsite(){
        return BaseService.apiGetRequest('auth/getuserwebsite');
    }
    setThemeskin(theme) {
        return BaseService.apiPostRequest('auth/themeskin/settheme', theme);
    }
 	setThemeskinCustom(theme) {
        return BaseService.apiPostRequest('auth/getthemecustom/settheme', theme);
    }
	getFileContentTheme(skinid) {
		return BaseService.apiPostRequest('get-template-file-content-theme', skinid);
	}

}
export default new ThemesService();
